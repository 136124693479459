import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import accessibilityKey from '../../../images/accessibility-key.png';
import accessibilityKey2x from '../../../images/accessibility-key@2x.png';
import accessibilityKey3x from '../../../images/accessibility-key@3x.png';
import chart1HighRes from '../../../images/contrast-chart-1-high-res.jpg';
import chart2HighRes from '../../../images/contrast-chart-2-high-res.jpg';
import grayBox from '../../../images/gray-box-contrast-key.svg';
import greenBox from '../../../images/green-box-contrast-key.svg';
import orangeBox from '../../../images/orange-box-contrast-key.svg';
import accessibilityChart1 from '../../../images/accessibility-chart-1.png';
import accessibilityChart12x from '../../../images/accessibility-chart-1@2x.png';
import accessibilityChart13x from '../../../images/accessibility-chart-1@3x.png';
import accessibilityChart2 from '../../../images/accessibility-chart-2.png';
import accessibilityChart22x from '../../../images/accessibility-chart-2@2x.png';
import accessibilityChart23x from '../../../images/accessibility-chart-2@3x.png';
import { SprkLink, SprkIcon, SprkDivider, SprkStack, SprkStackItem } from '@sparkdesignsystem/spark-react';
export const accessibilityKeySet = `${accessibilityKey} 560w, ${accessibilityKey2x} 1120w, ${accessibilityKey3x} 1680w`;
export const accessibilityChart1Set = `${accessibilityChart1} 560w, ${accessibilityChart12x} 1120w, ${accessibilityChart13x} 1680w`;
export const accessibilityChart2Set = `${accessibilityChart2} 560w, ${accessibilityChart22x} 1120w, ${accessibilityChart23x} 1680w`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  accessibilityKeySet,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <h1>{`Color Accessibility`}</h1>
    <p>{`  To account for the needs of all people, we follow `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/"
      }}>{`WCAG 2.1 (Level AA) contrast guidelines`}</a>{`.`}</p>
    <h2>{`Contrast Charts`}</h2>
    <p>{`Use these charts to discern contrast accessibility between two colors.`}</p>
    <p className="sprk-b-TypeBodyTwo sprk-u-mbl sprk-u-Measure">
  The contrast outcomes between two colors can have three possibilities:
    </p>
    <SprkStack splitAt="tiny" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkStack">
  <SprkStackItem additionalClasses="sprk-o-Stack__item--fourth@xs sprk-o-Stack__item--center-column sprk-u-TextAlign--center" mdxType="SprkStackItem">
    <img src={grayBox} alt="A gray box indicating failed contrast" />
  </SprkStackItem>
  <SprkStackItem additionalClasses="sprk-o-Stack__item--three-fourths@xs sprk-o-Stack__item--center-column" mdxType="SprkStackItem">
    <span className="sprk-u-FontWeight--bold">Do Not Use</span> - These two colors fail to meet contrast requirements.
  </SprkStackItem>
    </SprkStack>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure sprk-u-mvl" mdxType="SprkDivider" />
    <SprkStack splitAt="tiny" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkStack">
  <SprkStackItem additionalClasses="sprk-o-Stack__item--fourth@xs sprk-o-Stack__item--center-column sprk-u-TextAlign--center" mdxType="SprkStackItem">
    <img src={greenBox} alt="A green box with a checkmark indicating successful contrast" />
  </SprkStackItem>
  <SprkStackItem additionalClasses="sprk-o-Stack__item--three-fourths@xs sprk-o-Stack__item--center-column" mdxType="SprkStackItem">
    <p>
      <span className="sprk-u-FontWeight--bold">Successful Contrast</span> - Indicates a 4.5:1 contrast ratio, and meets requirements for all text and graphical elements.
    </p>
    <p className="sprk-b-TypeBodyTwo sprk-u-mbn sprk-u-Measure">
      If text is smaller than 18px (or 14px bold) it must have 4.5:1 contrast ratio.
    </p>
  </SprkStackItem>
    </SprkStack>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure sprk-u-mvl" mdxType="SprkDivider" />
    <SprkStack splitAt="tiny" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkStack">
  <SprkStackItem additionalClasses="sprk-o-Stack__item--fourth@xs sprk-o-Stack__item--center-column sprk-u-TextAlign--center" mdxType="SprkStackItem">
    <img src={orangeBox} alt="An orange error box warning to be be mindful of meeting contrast conditions." />
  </SprkStackItem>
  <SprkStackItem additionalClasses="sprk-o-Stack__item--three-fourths@xs sprk-o-Stack__item--center-column" mdxType="SprkStackItem">
    <p>
      <span className="sprk-u-FontWeight--bold">Needs Consideration</span> - Indicates a 3:1 contrast ratio, and should only be used for large text and graphical elements
    </p>
    <p className="sprk-b-TypeBodyTwo sprk-u-mbn sprk-u-Measure">
      At least 18px (or 14px bold) is considered large text.
    </p>
  </SprkStackItem>
    </SprkStack>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure sprk-u-mvl" mdxType="SprkDivider" />
    <p className="sprk-u-FontWeight--bold sprk-u-mvh sprk-u-Measure">Each chart image clicks through to view a high resolution version.</p>
    <a href={chart1HighRes} className="sbdocs-a sprk-u-Measure">
  <img srcSet={accessibilityChart1Set} src={accessibilityChart1} sizes="(min-width: 1120px) 1120px,
          (min-width: 1680px) 1680px,
          560px" className="sprk-u-mbm" alt="A chart of colors comparing contrast accessibility." />
    </a>
    <a href={chart2HighRes} className="sbdocs-a sprk-u-Measure">
  <img srcSet={accessibilityChart2Set} src={accessibilityChart2} sizes="(min-width: 1120px) 1120px,
          (min-width: 1680px) 1680px,
          560px" className="sprk-u-mbh" alt="A chart of colors comparing contrast accessibility." />
    </a>
    <p className="sprk-u-FontWeight--bold sprk-u-mbh sprk-u-Measure sprk-u-mbl">Each chart image clicks through to view a high resolution version.</p>
    <SprkDivider element="span" additionalClasses="sprk-u-mbl" mdxType="SprkDivider" />
    <SprkLink element="a" href="/using-spark/foundations/color-codes" variant="has-icon" mdxType="SprkLink">
  <SprkIcon additionalClasses="
      sprk-u-mrs
      sprk-c-Icon--m
      sprk-c-Icon--stroke-current-color" iconName="arrow-left" mdxType="SprkIcon" />
  Previous: Color Codes
    </SprkLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      